import React from "react";
import google from "../img/logos/google.png";
import yelp from "../img/logos/yelp.png";
import fb from "../img/logos/fb.png";
import angies from "../img/logos/angies.png";
import porch from "../img/logos/porch.png";
import houzz from "../img/logos/houzz.png";
import guildquality from "../img/logos/guildquality.png";

const ReviewTilesComponent = ({ data }) => {
  return (

    <div className="max-w-7xl flex flex-wrap py-3 mx-auto">
      <div className="w-full flex items-center">
        <div className="grid grid-cols-3 md:grid-cols-6 gap-3 md:gap-5 text-center w-full">
          <div className="text-center">
            <img
              src={yelp}
              alt="Yelp Reviews"
              className="mb-0 mx-auto"
              style={{ maxWidth: "72px", marginTop: "-4px" }}
            />
            <div>
              <span className="block text-gray-900 font-bold">5/5</span>
            </div>
          </div>
          <div>
            <img
              src={google}
              alt="Google Reviews"
              className="mb-0 mx-auto"
              style={{ maxWidth: "75px" }}
            />{" "}
            <div>
              <span className="block text-gray-900 font-bold">4.9/5</span>
            </div>
          </div>
          <div>
            <img
              src={fb}
              alt="Facebook Reviews"
              className="mb-0 mx-auto"
              style={{ maxWidth: "85px" }}
            />
            <div>
              <span className="block text-gray-900 font-bold">5/5</span>
            </div>
          </div>
          <div>
            <img
              src={angies}
              alt="Angies List Reviews"
              className="mb-0 mx-auto"
              style={{ maxWidth: "85px" }}
            />
            <div>
              <span className="block text-gray-900 font-bold">4.9/5</span>
            </div>
          </div>
          <div>
            <img
              src={houzz}
              alt="Houzz Reviews"
              className="mb-0 mx-auto"
              style={{ maxWidth: "85px" }}
            />
            <div>
              <span className="block text-gray-900 font-bold">5/5</span>
            </div>
          </div>

          <div>
            <img
              src={guildquality}
              alt="GuildQuality Reviews"
              className="mb-0 mx-auto"
              style={{ maxWidth: "85px" }}
            />
            <div>
              <span className="block text-gray-900 font-bold">4.9/5</span>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default ReviewTilesComponent;
